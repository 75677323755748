.loader-body {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    display: flex;
    display: -ms-flexbox;
    -ms-flex-align: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
}

.loading {
    width: 40px;
    height: 40px;
    margin: 20px auto;
    position: relative;
}

.loading.bar div {
    width: 2px;
    height: 8px;
    background: #333;
    position: absolute;
    top: 20px;
    left: 20px;
    opacity: 0.05;
    -webkit-animation: fadeit 1.1s linear infinite;
    -moz-animation: fadeit 1.1s linear infinite;
    animation: fadeit 1.1s linear infinite;
}

.loading.bar div:nth-child(1) {
    -webkit-transform: rotate(0deg) translate(0, -8px);
    -moz-transform: rotate(0deg) translate(0, -8px);
    transform: rotate(0deg) translate(0, -8px);

    -webkit-animation-delay: 0.39s;
    -moz-animation-delay: 0.39s;
    animation-delay: 0.39s;
}

.loading.bar div:nth-child(2) {
    -webkit-transform: rotate(45deg) translate(0, -8px);
    -moz-transform: rotate(45deg) translate(0, -8px);
    transform: rotate(45deg) translate(0, -8px);

    -webkit-animation-delay: 0.52s;
    -moz-animation-delay: 0.52s;
    animation-delay: 0.52s;
}

.loading.bar div:nth-child(3) {
    -webkit-transform: rotate(90deg) translate(0, -8px);
    -moz-transform: rotate(90deg) translate(0, -8px);
    transform: rotate(90deg) translate(0, -8px);

    -webkit-animation-delay: 0.65s;
    -moz-animation-delay: 0.65s;
    animation-delay: 0.65s;
}

.loading.bar div:nth-child(4) {
    -webkit-transform: rotate(135deg) translate(0, -8px);
    -moz-transform: rotate(135deg) translate(0, -8px);
    transform: rotate(135deg) translate(0, -8px);

    -webkit-animation-delay: 0.78s;
    -moz-animation-delay: 0.78s;
    animation-delay: 0.78s;
}

.loading.bar div:nth-child(5) {
    -webkit-transform: rotate(180deg) translate(0, -8px);
    -moz-transform: rotate(180deg) translate(0, -8px);
    transform: rotate(180deg) translate(0, -8px);

    -webkit-animation-delay: 0.91s;
    -moz-animation-delay: 0.91s;
    animation-delay: 0.91s;
}

.loading.bar div:nth-child(6) {
    -webkit-transform: rotate(225deg) translate(0, -8px);
    -moz-transform: rotate(225deg) translate(0, -8px);
    transform: rotate(225deg) translate(0, -8px);

    -webkit-animation-delay: 1.04s;
    -moz-animation-delay: 1.04s;
    animation-delay: 1.04s;
}

.loading.bar div:nth-child(7) {
    -webkit-transform: rotate(270deg) translate(0, -8px);
    -moz-transform: rotate(270deg) translate(0, -8px);
    transform: rotate(270deg) translate(0, -8px);

    -webkit-animation-delay: 1.17s;
    -moz-animation-delay: 1.17s;
    animation-delay: 1.17s;
}

.loading.bar div:nth-child(8) {
    -webkit-transform: rotate(315deg) translate(0, -8px);
    -moz-transform: rotate(315deg) translate(0, -8px);
    transform: rotate(315deg) translate(0, -8px);

    -webkit-animation-delay: 1.3s;
    -moz-animation-delay: 1.3s;
    animation-delay: 1.3s;
}

@-webkit-keyframes fadeit {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeit {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeit {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}