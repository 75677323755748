@font-face {
    font-family: "Celias";
    src: local("Celias"), url(../fonts/Celias/Celias.ttf) format("truetype");
}

@font-face {
    font-family: "Celias-Light";
    src: local("Celias-Light"), url(../fonts/Celias/Celias-Light.ttf) format("truetype");
}

@font-face {
    font-family: "Celias-Medium";
    src: local("Celias-Medium"),
        url(../fonts/Celias/Celias-Medium.ttf) format("truetype");
}

/* Modal Backdrop fix */

#root>div,
#root>div>div {
    z-index: unset;
}

/*  */

body {
    font-family: "Celias";
    background-color: #dce2ec !important;
    /* background-color: #f3f8ff !important; */
    font-weight: 400;
    max-width: 100%;
    overflow-x: hidden;
    font-size: 13px;
    line-height: 1.4;
    color: #000;
}

:root {
    --prime: #0053ad;
}

input[type="checkbox"]:checked  {
    background-color: #0053ad !important;
}

input[type="radio"]:checked  {
    background-color: #0053ad !important;
}

.page-body {
    display: flex;
}

.page-content {
    width: 100%;
    margin-top: 55px;
}

.page-content-home {
    width: 100%;
    margin-top: 55px;
    padding: 40px;
}

.fw-bold {
    font-weight: 600 !important;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    /* font-family: "Celias" !important; */
    color: #000 !important;
    /* letter-spacing: 0.3px !important; */
    font-weight: 600 !important;
}

h6 {
    font-size: 16px;
}

p {
    color: #777;
    font-size: 13px;
}

small {
    color: #666;
    /* font-weight: 600; */
    font-size: 11px !important;
    letter-spacing: 0.4px;
}

label {
    color: #888 !important;
    font-size: 13px !important;
}

.text-dark {
    color: #000 !important;
}

.text-green {
    color: #008000 !important;
}

.text-red {
    color: #d30000;
}

.text-gray {
    color: #808080 !important;
}

.text-darkgray {
    color: #555 !important;
}

.text-light {
    color: #b8b8b8 !important;
}

.text-primary-light {
    color: #5682d7 !important;
}

.text-primary {
    color: var(--prime) !important;
}

.bg-fff {
    background-color: #fff !important;
}

.bg-gray {
    background-color: #bbbbbb !important;
}

.bg-lightgray {
    background-color: #f3f3f3 !important;
}

.border-right {
    border-right: 1px solid #e7e7e7;
}

.border-bottom {
    border-bottom: 1px solid #e7e7e7;
}


.border-none {
    border: none !important;
}

.no-radius {
    border-radius: 0 !important;
}

.list:hover {
    background: rgb(222, 233, 251) !important;
    border-radius: 3px;
}

.head-bar {
    background: #fff !important;
    padding: 10px 20px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    /* box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; */
}

.card {
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 1px 3px 3px 0px;
    border-radius: 6px;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.end {
    display: flex;
    align-items: center;
    justify-content: end !important;
    margin-left: auto !important;
}

.btn-radius {
    border-radius: 20px;
}

.btn-primary {
    background-color: var(--prime) !important;
    border: none !important;
}

.btn-primary-outline {
    border: 1px solid var(--prime);
    border-radius: 5px;
    color: var(--prime);
    background-color: #fff;
}

.btn-primary-outline:hover {
    color: #fff;
    background-color: var(--prime);
}

.btn-secondary-outline {
    border: 1px solid #aaa;
    background: #fff;
    color: #222;
}

.bottom-tabs {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 45px;
    z-index: 13;
    background: #fff;
}

/* Sidebar css */

.sidebar {
    color: #000;
    width: 100%;
    background-color: #fff;
    transition: margin-left 0.3s ease;
    box-shadow: 0px 1px 5px #ccc;
    position: fixed;
    z-index: 2;
    padding: 10px 0px;
    bottom: 0;
    top: 55px;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
}

.sidebar .w-auto-bottom {
    width: 100% !important;
    position: fixed;
    bottom: 0;
}

.sidebar-options {
    display: flex !important;
    justify-content: end;
    align-items: center;
}

.sidebar-footer {
    margin-top: 40px;
    padding: 0 10px;
    margin-bottom: 40px;
}

.sidebar-sticky {
    /* width: 100%; */
    /* margin-left: 15px;
    margin-right: 15px; */
    overflow-y: auto;
    height: 100%;
    /* height: calc(100vh - 350px); */
    /* scrollbar-width: none !important; */
}

.sidebar-header {
    position: sticky;
    padding: 10px;
}

.sidebar-brand {
    height: 90px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 500;
    color: #555 !important;
}

.sidebar-brand img {
    max-height: 90px;
}

.page-sidebar {
    display: none;
}

.sidebar-sticky ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidebar-sticky ul li a {
    margin-bottom: 5px;
    padding: 10px;
    display: block;
    text-decoration: none;
    color: #777 !important;
    font-size: 12px;
    font-weight: 600 !important;
    /* font-family: "poppins-medium"; */
    letter-spacing: 0.8px;
    /* text-transform: uppercase; */
    /* border-bottom: 1px solid #eee; */
}

.sidebar-sticky>ul>li:first-child>a {
    /* border-top: 1px solid #eee; */
}

.sidebar-sticky ul>li>a.active~.sidebar-dropdown-menu ul>li>a {
    /* background: #f8f8f8; */
}

.sidebar-sticky ul>li>a:hover,
.sidebar-sticky ul>li>a.active {
    font-weight: 600 !important;
    background: rgb(222, 233, 251) !important;
    /* border-bottom-color: #eee; */
    color: var(--prime) !important;
    border-right: 3px solid var(--prime) !important;
    /* border-radius: 10px; */
}

.sidebar-sticky ul li a p {
    font-weight: 600 !important;
    /* font-family: "poppins-medium"; */
    color: #dfdfdf;
    font-size: 12px !important;
    letter-spacing: 0.8px !important;
}

.sidebar-sticky ul>li>a>i {
    margin-right: 6px;
    color: #fff;
    width: 20px;
    text-align: center;
}

.sidebar-sticky ul>li>a>img {
    height: 22px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
}

.sidebar-sticky ul>li>a>span {}

.sidebar-dropdown .sidebar-dropdown-menu {
    display: none;
    padding-top: 3px;
}

.sidebar-dropdown .sidebar-dropdown-menu ul li a {
    padding-left: 30px;
}

.sidebar-dropdown .sidebar-dropdown-btn.active~.sidebar-dropdown-menu {
    display: block;
}

.sidebar-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    padding: 10px;
    z-index: 1301;
    box-shadow: none !important;
}

.sidebar-close span:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 25%;
    width: 100%;
    height: 100%;
    border-left: 2px solid #888;
    transform: rotate(45deg);
}

.sidebar-close span:after {
    content: "";
    position: absolute;
    top: 50%;
    left: -40%;
    width: 100%;
    height: 100%;
    border-right: 2px solid #888;
    transform: rotate(-45deg);
}

.sidebar-dropdown-arrow {
    float: right;
    margin-top: 2px;
}

.sidebar-toggler {
    position: relative;
    outline: 0 !important;
    box-shadow: none !important;
    border: 0;
    padding: 0 0 0 10px;
}

.sidebar-toggler span {
    width: 22px;
    height: 2px;
    background-color: #333;
    margin: 4px 0;
    display: block;
}

.sidebar-username {
    text-decoration: none !important;
    color: #6c757d !important;
}

.sidebar-options>a,
.sidebar-options>button {
    background: transparent;
    border: 0;
    outline: 0;
    text-decoration: none !important;
    color: #fff;
    margin-top: 5px;
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
}

.sidebar-options>a:hover,
.sidebar-options>button:hover {
    background: rgba(255, 255, 255, 0.2);
}

/*  */


/* Navbar css */

.navbar-custom {
    background: #fff;
    height: 55px;
    border-bottom: 1px solid #f3f3f3;
    z-index: 99;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px; */
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 5px;
}

.navbar-custom .logo-img {
    max-height: 35px;
    max-width: 200px;
}

.navbar-custom-new {
    background: #fff;
    height: 50px;
    border-bottom: 1px solid #f3f3f3;
    z-index: 99;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.navbar-custom-new .logo-img {
    max-height: 35px;
    max-width: 200px;
}

.navbar-custom {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 0px;
}

.navbar-custom .nav-link {
    padding: 15px 10px;
    color: #fff;
    font-weight: 500;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
}

.navbar-custom .nav-link>img {
    width: 28px;
}

.navbar-custom-new {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 0px;
}

.navbar-custom-new .nav-link {
    padding: 15px 10px;
    color: #fff;
    font-weight: 500;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
}

.navbar-custom-new .nav-link>img {
    width: 28px;
}

.navbar-search>.dropdown {
    width: 100%;
}

.navbar-profile {
    /* color: #fff; */
    text-align: center;
    margin-bottom: 20px;
    background-color: #fff;
    position: sticky;
    top: 0;
}

.navbar-profile-sm {
    max-width: 100px;
    max-height: 100px;
    border-radius: 100%;
    margin-bottom: 5px;
}

/*  */


/* Modal css */

.modal-header {
    border-bottom: none !important;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.modal-content {
    border-radius: 0px !important;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.modal-footer {
    border-top: none !important;
    padding: 0.5rem !important;
}

.modal-dialog {
    /* height: 100vh; */
}

.modal {
    padding: 0px !important;
}

/*  */

a {
    text-decoration: none !important;
    color: #000;
}

.btn {
    font-size: 14px;
}

.btn-sm {
    font-size: 13px;
    font-weight: 600;
}

/* .btn-sm:hover {
    background-color: #f7f7f7 !important;
    border-radius: 4px;
} */

.text-shrink {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.toast.notification {
    cursor: pointer;
    border: 0;
    position: fixed;
    z-index: 1200;
    bottom: 20px;
    left: 50%;
    margin-left: -175px;
    width: 100%;
    background: #333;
    color: #fff;
}

.dropdown-menu {
    font-size: 14px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: none !important;
    z-index: 1 !important;
    padding: 5px;
    min-width: none !important;
    width: 20rem;
}

.form-control,
.form-select {
    font-size: 14px;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.form-control:hover,
.form-select:hover {
    border-color: #4d57eb;
}

.form-control:focus,
.form-select:focus {
    border-color: none !important;
    box-shadow: none !important;
}

.input-group-text {
    font-size: 14px;
}

.cursor {
    cursor: pointer;
}

.pagination\:container {
    display: flex;
    align-items: center;
    justify-content: end;
}

.arrow\:text {
    display: block;
    vertical-align: middle;
    font-size: 13px;
    vertical-align: middle;
}

.pagination\:number {
    --size: 32px;
    --margin: 6px;
    margin: 0 var(--margin);
    border-radius: 6px;
    background: #e1e0e0;
    max-width: auto;
    min-width: var(--size);
    height: var(--size);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 6px;
}


.box {
    border: 1px dashed #d6d6d6;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 400px;
}

.pagination\:number:hover {
    background: lighten(#202020, 3%);
}

.pagination\:number.active {
    background: lighten(#202020, 3%) !important;
}

.pagination\:active {
    background: #4891ff !important;
    color: #fff !important;
    /* position: relative; */
}

.btn-blue-outline {
    border: 1px solid var(--prime);
    border-radius: 6px;
    color: var(--prime);
    font-size: 12px;
    text-align: center;
    padding: 5px;
}

.btn-red-outline {
    border: 1px solid #d30000;
    border-radius: 6px;
    color: #d30000;
    font-size: 12px;
    text-align: center;
    padding: 5px;
}

.btn-orange-outline {
    border: 1px solid #e78300;
    border-radius: 6px;
    color: #e78300;
    font-size: 12px;
    text-align: center;
    padding: 5px;
}

.tabs {
    display: flex;
}

.tabs .tabs-head {
    padding: 0px 10px;
    cursor: pointer;
}

.tabs .tabs-head p {
    font-size: 13px;
    padding: 6px 10px;
}

.f_tab p {
    color: #979797;
    font-weight: 600 !important;
    background: transparent !important;
    /* border: 1px solid #aaa;
    border-radius: 5px; */
    cursor: pointer;
}

.active_tab p {
    color: var(--prime);
    font-weight: 600 !important;
    background: #d7e6fd;
    border-radius: 5px;
    cursor: pointer;
}

.accordion-button:focus {
    z-index: 3;
    border-color: #dddddd !important;
    outline: 0;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    color: #000 !important;
    background-color: #f9f9f9 !important;
}

.accordion-button {
    padding: 1rem !important;
}

.accordion-button::after {
    width: 1.2rem !important;
    height: 1.2rem !important;
    background-size: 1.2rem !important;
}

.accordion-item {
    background-color: #fff;
    border: none !important;
}

.accordion-item:last-of-type .accordion-collapse {
    margin-top: 10px;
}

.accordion-body {
    padding: 0 !important;
}

.product-img {
    height: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
}

.product_img_cover {
    width: 100%;
    height: 70px;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
}

.MuiDrawer-paperAnchorBottom {
    /* min-height: 120px; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.MuiDrawer-paperAnchorBottom ul {
    list-style: none !important;
}

.MuiAccordion-root::before {
    background-color: transparent !important;
}

.MuiDrawer-paperAnchorRight {
    width: 30%;
}

.MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
}

.MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0 !important;
}

.MuiAccordionSummary-expandIcon {
    transform: rotate(0deg);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.scrollbar-none {
    scrollbar-Width: none !important;
}

.w-70 {
    width: 70%;
}

.w-30 {
    width: 30%;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: auto !important;
}


.css-b62m3t-container {
    z-index: 9;
}

.Price {
    z-index: 8 !important;
}

.bottom-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 45px;
    z-index: 1;
}

.rs-table-hover .rs-table-row:hover {
    background-color: #e0f4fa !important;
}

.h-auto {
    height: auto !important;
}

.para-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.disc-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 80px;
}

.select__control,
.select__menu {
    text-align: left !important;
}

.dropdown-toggle::after {
    margin-left: 0 !important;
    content: none !important;
}



/* Media quries */

@media(min-width:768px) {
    .bottom-buttons .left-button {
        /* left: 20px;  */
        bottom: 30px;
        position: fixed;
    }

    .md-border-right {
        border-right: 1px solid #ddd;
    }

    .bottom-buttons .right-button {
        right: 20px;
        bottom: 30px;
        position: fixed;
    }

    .md-text-end {
        text-align: right !important;
    }

    .d-md-block {
        display: block;
    }

    .md-ps-0 {
        margin-left: 0 !important;
    }

    .pt-md-0 {
        padding-top: 0 !important;
    }

    .bottom {
        bottom: 0px;
        position: fixed;
        width: 30%;
    }

    .border-md-right {
        border-right: 1px solid #ccc;
    }

    .px-md-3 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .border-right-md {
        border-right: 1px solid #ddd;
    }
}

@media(max-width:768px) {
    .modal-dialog {
        max-width: 100% !important;
        margin: 0px !important;
    }

    .sm-p-0 {
        padding: 0px !important;
    }

    .sm-mt-3 {
        margin-top: 1rem !important;
    }



    .d-md-none {
        display: none !important;
    }

    .d-sm-none {
        display: none;
    }

    .d-sm-block {
        display: block !important;
    }

    .sm-mb-2 {
        margin-bottom: 0.5rem;
    }

    .bottom {
        bottom: 0px;
        position: fixed;
        width: 100%;
    }
}


@media (max-width: 991px) {
    .page-content {
        margin-top: 55px !important;
    }

    .page-content-home {
        margin-top: 55px !important;
    }

    .sidebar {
        transition: 6.5s ease-in-out;
        display: none;
    }

    .sidebar.active {
        position: fixed;
        right: 0;
        top: 0;
        height: 100vh;
        /* width: calc(100% - 25%); */
        display: block;
        transition-property: top, left;
        transition-duration: 1s, 1s;
        transition-delay: 0s, 1s;
        color: #297ad0;
        z-index: 13 !important;
    }

    .sidebar-footer {
        margin-top: 40px;
        margin-bottom: 0;
    }

    .navbar-search {
        display: none;
    }

    .navbar-search.active {
        position: absolute;
        left: 10px;
        right: 10px;
        background: #fff;
        z-index: 111111;
        top: 0;
        height: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
    }

    .navbar-search.active>.dropdown {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media (min-width: 992px) {

    .page-sidebar {
        width: 27%;
    }

    .page-content {
        margin-left: 220px;
    }

    .page-content-home {}

    .navbar-search {
        width: 25%;
    }

    .navbar-custom .logo-img {
        max-height: 35px;
        width: auto;
        max-width: 200px;
    }

    .navbar-custom-new .logo-img {
        max-height: 35px;
        width: auto;
        max-width: 200px;
    }

    .navbar-brand.navlogo {
        width: 17%;
        text-align: center;
    }


    .sidebar-sticky::-webkit-scrollbar {
        width: 5px;
        height: 4px;
    }

    .sidebar-sticky::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.2);
    }

    .sidebar-sticky::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.3);
    }

    .sidebar-sticky::-webkit-scrollbar-thumb:hover {
        background: rgba(255, 255, 255, 0.4);
    }

    .sidebar-sticky {
        scrollbar-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.2);
        scrollbar-width: thin;
    }

    .sidebar {
        margin-left: 0;
        width: 220px;
    }

    .sidebar .w-auto-bottom {
        width: 220px !important;
        position: fixed;
        bottom: 0;
    }

    .sidebar .sidebar-header .sidebar-close {
        display: none;
    }
}

@media(max-width: 540px) {
    .Dropzone {
        width: 100% !important;
    }

    .mx-sm-0 {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .sm-text-left {
        text-align: left !important;
    }

    .p-sm-0 {
        padding: 0px !important;
    }

    .px-sm-0 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .py-sm-0 {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .pt-sm-2 {
        padding-top: 0.5rem !important;
    }

    .navbar-profile {
        margin-top: 0px;
    }

    .scroll-row {
        height: 70vh;
        width: 200px;
        /* padding: 0px 5px; */
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .scroll-flex {
        display: flex;
        width: 2000px !important;
    }

    .scroll-nav .nav {
        scrollbar-width: none;
    }
}

@media(min-width: 541px) {
    .mx-md-3 {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    /* .navbar-profile {
        margin-top: 25px !important;
    } */
}


/* Toggle Switch Start */
.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.react-switch-label {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    cursor: pointer;
    width: 40px;
    height: 20px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color 0.2s;

}

.react-switch-label .react-switch-button {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

/* Toggle Switch End */

.note_hide {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.scroll-data {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 400px;
}

.catbut {
    /* height: 35px; */
    /* justify-content: left; */
    /* display: flex; */
    align-items: center;
    border-bottom: #212529 solid 1px;
}

.error_message {
    color: rgb(197, 29, 29);
}

.scroll-row {
    height: 70vh;
    width: 200px;
    /* padding: 0px 5px; */
    overflow-y: scroll;
    overflow-x: hidden;
}

.scroll-flex {
    display: flex;
    width: 2000px !important;
}

.scroll {
    overflow: scroll;
}

.Dropzone {
    height: 130px;
    width: 50%;
    background-color: #fff;
    /* border: 2px dashed rgb(187, 186, 186); */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    margin: 0px auto;
}

.Icon {
    opacity: 0.3;
    height: 64px;
    width: 64px;
}

.FileInputNone {
    display: none;
}

/* .Highlight {
    background-color: rgb(43, 31, 218);
} */

.dropzone_img {
    object-fit: cover;
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
    border-radius: 5px;
}

.details_img {
    object-fit: contain;
    width: 100%;
    height: 150px;
    /* background-color: #f7f7f7; */
    border-radius: 5px;
}

.product_img {
    object-fit: contain;
    width: 100%;
    height: 70px;
}

.pre_img {
    object-fit: contain;
    width: 100%;
    height: 100px;
}

.cover_img {
    object-fit: contain;
    width: 100%;
    height: 100px;
}



.w-200 {
    width: 200px;
}

.accordion-expandable .accordion-expand-hight {
    height: auto !important;
}

.accordion-h-60 {
    height: 60px !important;
}

.radius {
    border-radius: 6px !important;
}

.react-datepicker-ignore-onclickoutside,
.react-datepicker__input-container input {
    padding: 0.375rem 0.75rem !important;
    border: 1px solid #ced4da !important;
    color: #212529 !important;
    border-radius: 0.25rem !important;
    width: 100% !important;
}

.react-datepicker-popper {
    z-index: 30 !important;
}

.shadow {
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset !important;
}

.oneline {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.double {
    /* max-height: 35px; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.img-list-height {
    height: 100px !important;
}

.container-img-list .top-right {
    position: absolute;
    top: 0px;
    right: 5px;
}

.container-img-list {
    position: relative;
    text-align: center;
    color: white;
}

.main-select {
    width: 100%;
    height: 100px;
}

.tab-list {
    border-bottom: 1px solid #ccc;
    padding-left: 0;
}

.tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
}

.tab-list-active {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
}






.search-bar {
    border: none !important;
    letter-spacing: 0.2;
    background-color: none !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.search-bar {
    width: 100%;
}

.search-bar .form-control {
    --input-bg: var(--secondary-color-lighter);
    width: 100%;
    border-color: transparent;
    border-radius: 15px;
}

.search-bar .btn {
    --btn-bg: var(--secondary-color-lighter);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 6px 18px 18px;
    border-color: transparent;
    border-radius: 15px;
}

.input-icons {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 10px;
    /* background-color: #005d86; */
    width: 100%;
    height: 36px;
    align-items: center;
}

.input-icons input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    height: 30px;
}

.border-left {
    border-left: 1px solid #eeeff0 !important;
}

.infinite-scroll-component {
    overflow-x: hidden !important;
    scrollbar-width: none !important;
}

.fixed-sub-header {
    position: sticky;
    top: 54px;
    z-index: 1;
}

.shadow-bottom {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}


.border {
    border: 1px solid #c2c7cc !important;
}





.control {
    width: 121%;
}

.column {
    float: left;
    /* width: 25%; */
    padding: 1px;
    /* height: 70px; */
    /* width: 100%; */
}

.card-img {
    border: 1px solid lightgray;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 1px 3px 3px 0px;
    border-radius: 6px;
    padding: 3px;
}

.img-ss {
    height: 120px;
    width: 100%;
    object-fit: contain;
}

.overlay {
    position: absolute;
    top: 2px;
    left: 4px;
}

.hover-img img:hover {
    opacity: 0.5;

}

.selected_img {
    /* background-color: #dbe9ff; */
    border: 1px solid var(--prime);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 1px 3px 3px 0px;
    border-radius: 6px;
    padding: 3px;
}

/* .bottom-buttons{
    bottom: 20px;
    position: fixed;
} */

.over-img {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #fff;
    padding: 5px;
    border-radius: 4px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.overflow-img-scroll {
    overflow-y: scroll;
    /* min-height: 200px; */
    max-height: 300px;
    scrollbar-width: none;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    background-color: #edf2f9;
    border-radius: 10px;
}

.h-300 {
    height: 300px;
}

.h-500 {
    height: 500px;
}

.scroll-auto {
    overflow: auto;
}

.active_cat {
    background-color: rgb(222, 233, 251);
}

.active_cat p {
    color: #000;
}

.cat_hover:hover {
    background-color: rgb(222, 233, 251);
    color: #000 !important;
}


.fw-500 {
    font-weight: 500 !important;
}

.v-small {
    font-size: 12px !important;
}

.spec_head {
    /* background-color: lavender;
padding: 0px 10px; */
}

.cat_img {
    width: 100%;
    height: 20px;
    object-fit: contain;
}

.cat-child {
    width : 200px;
    cursor: pointer;
}

.scroll-x {
    overflow-x: scroll;
}

.scrollX {
    overflow-x: scroll;
    width: 100%;
}

.custom-input {
    border: transparent !important;
}

.nav.nav-pills .nav-link.active::after {
    content: "";
    position: absolute;
    left: 25%;
    bottom: 0px;
    width: 50%;
    height: 2px;
    background: #0053ad;
}

.nav.nav-pills .nav-link.active {
    background-color: #fff;
    color: #0053ad;
    font-weight: 600;
}

.nav.nav-pills .nav-link {
    color: #7a7a7a;
    display: inline-block;
    position: relative;
    padding: 10px 16px;
}

.scroll-nav .nav {
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
    padding-bottom: 12px;
}

.scroll-nav .nav .nav-link {
    display: inline-block;
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

/* .form-check-input:disabled {
    background-color: #e3e3e3 !important;
} */

.form-check-input {
    border: 1px solid #888888 !important;
}

::placeholder {
    font-size: 12px !important;
}

.d-contents {
    display: contents;
}

/* .contents .thead {
    border: 2px solid #000;
} */

.overflowY {
    overflow-y: scroll;
    max-height: 600px;
    scrollbar-width: none !important;
}

.sticky-top {
    position: sticky;
    top: 0;
    background-color: #f3f3f3 !important;
    z-index: 11;
    border-bottom: none !important;
}

.sticky-top tr th {
    background-color: #e3e7f0 !important;
    font-weight: 600;
    color: #000;
    border-bottom: 1px solid #eaf1fc;
}

.review_img {
    width: 100%;
    height: 50px;
    object-fit: contain;
}

.expandable {
    background-color: #f7f7f7;
    box-shadow: rgb(227, 227, 227) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.rct-node-icon {
    color: #5e5f5f !important;
    font-size: 12px;
}

.rct-title {
    font-size: 13px;
}

.rct-text {
    padding-bottom: 10px;
}

.table> :not(:first-child) {
    border-top: none !important;
}

.sticky-top55 {
    position: sticky;
    top: 55px;
    background-color: #fff;
    z-index: 11;
}

.cursor-hover:hover {
    background-color: #f4f4f4;
    ;
}

.react-datepicker-ignore-onclickoutside,
.react-datepicker__input-container input {
    padding: 0.6rem 0.75rem !important;
}

.pad-table th,
.pad-table td {
    padding: 0.5rem;
}

.btn-page {
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    padding: 5px 20px;
}

.cat_spc_hover {
    color: gray !important;
}

.cat_spc_hover:hover {
    color: #0e5bce !important;
    font-weight: 600;
}

.active_spc_cat {
    color: #0e5bce !important;
    font-weight: 600;
    background-color: #e1e9f4;
    border-radius: 10px;
}

.active_tab::before {
    content: '';
    position: absolute;
    left: 100%;
    top: 5%;
    display: inline-block;
    width: 0;
    height: 0;
    border-top: solid 1rem transparent;
    border-bottom: solid 1rem transparent;
    border-right: solid 1.5rem transparent;
    border-left: solid 1.5rem;
    border-left-color: currentcolor;
    border-left-color: white;

}

.active_tab::after {
    content: attr(data-text);
    position: absolute;
    left: 50%;
    top: 5%;
    display: inline-block;
    width: 100%;
    color: white;
    text-align: center;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -moz-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translate3d(-50%, -50%, 0);
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    -moz-transition: color 0.25s;
    -o-transition: color 0.25s;
    -webkit-transition: color 0.25s;
    transition: color 0.25s;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}



.active_tap {
    background: white;
    background-image: -moz-linear-gradient(left, #ffffff 0%, #ffffff 50%, #333333 50%, #333333 100%, #333333 100%);
    background-image: -webkit-linear-gradient(left, #ffffff 0%, #ffffff 50%, #333333 50%, #333333 100%, #333333 100%);
    background-image: linear-gradient(to right, #ffffff 0%, #ffffff 50%, #333333 50%, #333333 100%, #333333 100%);
    background-size: 200% 200%;
    background-position: 0;

}


.variant_img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 5px;
}

.extra-small {
    font-size: 11px !important;
}

.v-extra-small {
    font-size: 10px !important;
}

.active_sub {
    background-color: #f8fafb;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    border-radius: 10px;
}

.hr-drawer {
    width: 10%;
    background-color: #c5c5c5;
    height: 3px !important;
    opacity: 1 !important;
    margin: 0.54rem !important;
}

.sticky_drawer {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 12;
}

.form-check-input:disabled {
    background-color: #dadada !important;
}

.lds-dual-ring {
    display: inline-block;
    width: 20px;
    height: 20px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 14px;
    height: 14px;
    margin: 4px;
    border-radius: 50%;
    border: 2px solid #bfdff5;
    border-color: #6d8ffd transparent #6d8ffd transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.variant_img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.img_over_content {
    /* position: absolute;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    background: linear-gradient(to top, rgb(0, 38, 71), transparent);
    bottom: 0px;
    padding: 5px;
    width: 98%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */

    position: relative;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    background: linear-gradient(to top, #002647, transparent);
    /* background: linear-gradient(to top, rgba(0, 0, 0, 0.99), rgba(0, 0, 0, 0.39)); */
    bottom: 40px;
    padding: 5px;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.row_img_over_content {
    position: relative;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    background: linear-gradient(to top, #002647, transparent);
    /* background: linear-gradient(to top, rgba(0, 0, 0, 0.99), rgba(0, 0, 0, 0.39)); */
    bottom: 43px;
    padding: 5px;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.img_over_content_gallery {
    position: absolute;
    background: linear-gradient(to top, rgb(0, 38, 71), transparent);
    /* background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent); */
    bottom: 3px;
    padding: 5px;
    width: 96%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 600;
}

.img_over_content_gallery .img-name {
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}


.Xhidden {
    overflow-x: hidden !important;
}

.shadow-btn {
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.scrolling-wrapper {
    -webkit-overflow-scrolling: touch;

}

.h-170 {
    overflow-y: hidden;
    height: 170px;
}

.overflowY-modal {
    min-height: auto;
    max-height: 540px;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* .css-13r42pa-control {
    background-color: lightgray !important;
} */

.select__indicator {
    padding: 5px !important;
}

.select__value-container {
    padding: 2px 5px !important;
}

.cursor-none {
    cursor: not-allowed !important;
}


.form-check-input:checked {
    background-color: var(--prime);
    border: none !important;
}

.form-check-input-custom:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input-custom:checked {
    background-color: var(--prime);
    border: none !important;
}

.form-check-input-custom[type="checkbox"] {
    border-radius: 1.25em;
}

.form-check-input-custom {
    border: 1px solid #b0b0b0 !important;
}

.form-check-input-custom {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

.card-shadow {
    /* border: 1px solid var(--prime) !important; */
    box-shadow: rgb(155, 191, 244) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
}

.card-border {
    border: 1px solid var(--prime) !important;
}


.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #68a3fc;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}


.logo-img {
    width: 16px;
    height: 16px;

}

.sticky-bottom {
    position: sticky;
    bottom: 0;
    /* z-index: 99; */
    left: 0;
}

.profile-sidemenu-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.gallery_img {
    width: 100%;
    height: 140px;
    object-fit: contain;
    background-color: #f7f7f7;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.position-check {
    position: absolute;
    top: 3px;
    right: 5px;
}

.position-check-left {

    position: absolute;
    top: 3px;
    left: 5px;
}

.h-600 {
    height: 600px;
}

.brand_img {
    width: 100%;
    height: 140px;
    object-fit: contain;
    display: flex;
    justify-content: center;
}

.brand_img1 {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.add-more {
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    border-radius: 8px;
}


.form-select {
    padding: 0.375rem 0rem 0.375rem 0.2rem !important;
    background-position: right 0.2rem center !important;
    background-size: 12px !important;
}

.rct-collapse.rct-collapse-btn {
    cursor: pointer;
    padding: 2px 10px;
}



.bg-lightblue {
    background-color: rgb(198, 214, 241) !important;
}

.border-top-radius {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.border-bottom-radius {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.w-30px {
    width: 30px;
}

.border-prime {
    border: 1px solid var(--prime);
    border-radius: 4px;
}

.add_img {
    object-fit: contain;
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
    border-radius: 5px;
}

.addon_icons {
    width: 20px !important;
}

.rct-text .fas.fa-check-square,
.rct-node-icon .fas.fa-check-square {
    /* color: #058c46 !important; */
    color: #297ad0 !important;
    font-size: 15px;
}

.rct-node-icon .fas.fa-square,
.rct-text .fas.fa-square,
.rct-text .fas.fa-minus-square {
    font-size: 15px;
}

.rct-collapse.rct-collapse-btn {
    cursor: pointer;
    padding: 6px 9px;
    border-radius: 6px;
    margin-right: 15px;
    border: 2px solid #ccc;
}

.rct-collapse.rct-collapse-btn .fas.fa-minus,
.rct-collapse.rct-collapse-btn .fas.fa-plus {
    font-size: 12px;
    color: gray;
}

.react-checkbox-tree ol ol {
    padding-left: 50px !important;
}

.react-checkbox-tree label {
    margin-bottom: 0;
    cursor: pointer;
    padding: 2px 3px;
    border-radius: 4px;
}

.react-datepicker__header {
    text-align: center;
    background-color: #1367a9 !important;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: #ffffff !important;
}

.react-datepicker__day-name {
    color: #fff !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    border-bottom-color: #1367a9 !important;
}

.react-datepicker__time-name {
    color: #333;
}

.h-90vh {
    height: 90vh
}

.top-scroll-btn {
    position: fixed;
    width: 100%;
    left: 90%;
    bottom: 90px;
    height: 20px;
    font-size: 3rem;
    z-index: 1;
    cursor: pointer;
    color: green;
}

.round-radius {
    border-radius: 50%;
    padding: 0 !important;
    width: 40px;
    height: 40px;
    text-align: center;
}

.hover-row :hover {
    background-color: #f3f3f3 !important;
}

.rct-text .fas.fa-check-square, .rct-node-icon .fas.fa-check-square {
    color: #0053ad !important;
    font-size: 15px;
  }

.modal-centralize-product-term {
    display: flex; 
    align-items: center; 
    gap: 10px; 
    /* font-family: Arial, sans-serif;  */
    font-size: 14px;
}